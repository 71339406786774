<template>
  <div class="width_1920">
    <img class="image1" src="../../assets/img/sports/show/Pic_top.png" />
    <div v-if="projectList.length != 0" class="name">
      <div class="w1790"></div>
      <div
        v-if="projectList[0]"
        :class="
          projectList[0].title.length == 4
            ? 'w107'
            : projectList[0].title.length == 5
            ? 'w158'
            : 'w165'
        "
      >
        <div
          :class="
            projectList[0].status != 1 ? 'normal_text' : 'current_text center'
          "
        >
          {{ projectList[0].title }}
        </div>
      </div>

      <div class="w152"></div>
      <div
        v-if="projectList[1]"
        :class="
          projectList[1].title.length == 4
            ? 'w107'
            : projectList[1].title.length == 5
            ? 'w158'
            : 'w165'
        "
      >
        <div
          :class="
            projectList[1].status != 1 ? 'normal_text' : 'current_text center'
          "
        >
          {{ projectList[1].title }}
        </div>
      </div>

      <div class="w152"></div>
      <div
        v-if="projectList[2]"
        :class="
          projectList[2].title.length == 4
            ? 'w107'
            : projectList[2].title.length == 5
            ? 'w158'
            : 'w165'
        "
      >
        <div
          :class="
            projectList[2].status != 1 ? 'normal_text' : 'current_text center'
          "
        >
          {{ projectList[2].title }}
        </div>
      </div>
      <div class="w152"></div>
      <div
        v-if="projectList[3]"
        :class="
          projectList[3].title.length == 4
            ? 'w107'
            : projectList[3].title.length == 5
            ? 'w158'
            : 'w165'
        "
      >
        <div
          :class="
            projectList[3].status != 1 ? 'normal_text' : 'current_text center'
          "
        >
          {{ projectList[3].title }}
        </div>
      </div>
      <div class="w152"></div>
      <div
        v-if="projectList[4]"
        :class="
          projectList[4].title.length == 4
            ? 'w107'
            : projectList[4].title.length == 5
            ? 'w158'
            : 'w165'
        "
      >
        <div
          :class="
            projectList[4].status != 1 ? 'normal_text' : 'current_text center'
          "
        >
          {{ projectList[4].title }}
        </div>
      </div>
      <div class="w152"></div>
      <div
        v-if="projectList[5]"
        :class="
          projectList[5].title.length == 4
            ? 'w107'
            : projectList[5].title.length == 5
            ? 'w158'
            : 'w165'
        "
      >
        <div
          :class="
            projectList[5].status != 1 ? 'normal_text' : 'current_text center'
          "
        >
          {{ projectList[5].title }}
        </div>
      </div>
      <div class="w152"></div>
      <div
        v-if="projectList[6]"
        :class="
          projectList[6].title.length == 4
            ? 'w107'
            : projectList[6].title.length == 5
            ? 'w158'
            : 'w165'
        "
      >
        <div
          :class="
            projectList[6].status != 1 ? 'normal_text' : 'current_text center'
          "
        >
          {{ projectList[6].title }}
        </div>
      </div>
      <div class="w152"></div>
      <div
        v-if="projectList[7]"
        :class="
          projectList[7].title.length == 4
            ? 'w107'
            : projectList[7].title.length == 5
            ? 'w158'
            : 'w165'
        "
      >
        <div
          :class="
            projectList[7].status != 1 ? 'normal_text' : 'current_text center'
          "
        >
          {{ projectList[7].title }}
        </div>
      </div>
      <div class="total_score center">总 分</div>
    </div>
    <div class="pos_b" v-for="(item, index) in sportslist" :key="index">
      <div v-if="index == 0" class="black_bg"></div>
      <div v-else-if="index == 1" class="black_bg1"></div>
      <div v-else-if="index == 2" class="black_bg2"></div>
      <div v-else class="black_bg3"></div>
      <div class="label">
        <div class="rank_num">{{ item.rank }}</div>
        <div class="row">
          <img class="image" :src="item.logo" />
          <div class="team_text w765">{{ item.teamName }}</div>
        </div>
        <div v-if="index == 0" class="color1"></div>
        <div v-if="index == 1" class="color2"></div>
        <div v-if="index == 2" class="color3"></div>
        <div v-if="index == 3" class="color4"></div>
        <div v-if="index == 4" class="color5"></div>
        <div v-if="index == 5" class="color6"></div>
        <div v-if="index == 6" class="color7"></div>
        <div v-if="index == 7" class="color8"></div>
        <div class="w98"></div>
        <div
          v-if="projectList[0]"
          :class="
            projectList[0].title.length == 4
              ? 'w107'
              : projectList[0].title.length == 5
              ? 'w158'
              : 'w165'
          "
        >
          <div
            :class="
              item.scores[0]
                ? projectList[0].status != 1
                  ? 'score2 center'
                  : 'score1 center'
                : 'score3 center'
            "
          >
            {{ item.scores[0] ? item.scores[0] : "--" }}
          </div>
        </div>

        <div class="w152"></div>
        <div
          v-if="projectList[1]"
          :class="
            projectList[1].title.length == 4
              ? 'w107'
              : projectList[1].title.length == 5
              ? 'w158'
              : 'w165'
          "
        >
          <div
            :class="
              item.scores[1]
                ? projectList[1].status != 1
                  ? 'score2 center'
                  : 'score1 center'
                : 'score3 center'
            "
          >
            {{ item.scores[1] ? item.scores[1] : "--" }}
          </div>
        </div>
        <div class="w152"></div>
        <div
          v-if="projectList[2]"
          :class="
            projectList[2].title.length == 4
              ? 'w107'
              : projectList[2].title.length == 5
              ? 'w158'
              : 'w165'
          "
        >
          <div
            :class="
              item.scores[2]
                ? projectList[2].status != 1
                  ? 'score2 center'
                  : 'score1 center'
                : 'score3 center'
            "
          >
            {{ item.scores[2] ? item.scores[2] : "--" }}
          </div>
        </div>
        <div class="w152"></div>
        <div
          v-if="projectList[3]"
          :class="
            projectList[3].title.length == 4
              ? 'w107'
              : projectList[3].title.length == 5
              ? 'w158'
              : 'w165'
          "
        >
          <div
            :class="
              item.scores[3]
                ? projectList[3].status != 1
                  ? 'score2 center'
                  : 'score1 center'
                : 'score3 center'
            "
          >
            {{ item.scores[3] ? item.scores[3] : "--" }}
          </div>
        </div>
        <div class="w152"></div>
        <div
          v-if="projectList[4]"
          :class="
            projectList[4].title.length == 4
              ? 'w107'
              : projectList[4].title.length == 5
              ? 'w158'
              : 'w165'
          "
        >
          <div
            :class="
              item.scores[4]
                ? projectList[4].status != 1
                  ? 'score2 center'
                  : 'score1 center'
                : 'score3 center'
            "
          >
            {{ item.scores[4] ? item.scores[4] : "--" }}
          </div>
        </div>
        <div class="w152"></div>
        <div
          v-if="projectList[5]"
          :class="
            projectList[5].title.length == 4
              ? 'w107'
              : projectList[5].title.length == 5
              ? 'w158'
              : 'w165'
          "
        >
          <div
            :class="
              item.scores[5]
                ? projectList[5].status != 1
                  ? 'score2 center'
                  : 'score1 center'
                : 'score3 center'
            "
          >
            {{ item.scores[5] ? item.scores[5] : "--" }}
          </div>
        </div>
        <div class="w152"></div>
        <div
          v-if="projectList[6]"
          :class="
            projectList[6].title.length == 4
              ? 'w107'
              : projectList[6].title.length == 5
              ? 'w158'
              : 'w165'
          "
        >
          <div
            :class="
              item.scores[6]
                ? projectList[6].status != 1
                  ? 'score2 center'
                  : 'score1 center'
                : 'score3 center'
            "
          >
            {{ item.scores[6] ? item.scores[6] : "--" }}
          </div>
        </div>
        <div class="w152"></div>
        <div
          v-if="projectList[7]"
          :class="
            projectList[7].title.length == 4
              ? 'w107'
              : projectList[7].title.length == 5
              ? 'w158'
              : 'w165'
          "
        >
          <div
            :class="
              item.scores[7]
                ? projectList[7].status != 1
                  ? 'score2 center'
                  : 'score1 center'
                : 'score3 center'
            "
          >
            {{ item.scores[7] ? item.scores[7] : "--" }}
          </div>
        </div>
        <div class="total_score1 center">
          {{ item.scores[projectList.length] }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: "",
      sportslist: [],
      projectList: [],
    };
  },
  mounted() {
    let that = this;
    that.getRankList();
    setInterval(() => {
      setTimeout(() => {
        that.getRankList();
      }, 0);
    }, 5000);
  },
  methods: {
    getRankList() {
      var that = this;
      let data = {
        standId: "1589556897424543745",
      };
      that.api.s_sports.getRankList(data).then((res) => {
        if (res.code == 200) {
          this.projectList = res.result.projects;
          this.sportslist = res.result.teamRank;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.display-flex {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.width_1920 {
  width: 100%;
  height: 1500px;
  background: #1d132e;
  display: flex;
  flex-direction: column;
}
.w137 {
  width: 137px;
}

.image1 {
  width: 100%;
  height: 265px;
}

.name {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 73px;
}
.w1790 {
  width: 415px;
}
.rank_text {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffead1;
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.normal_text {
  font-size: 28px;
  font-family: FZZDHJW;
  font-weight: normal;
  color: #8167a2;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.current_text {
  font-size: 28px;
  font-family: FZZDHJW;
  font-weight: normal;
  color: #03ffff;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title_rank {
  margin-right: 30px;
}

.h91 {
  height: 91px;
}
.pos_b {
  position: relative;
  width: 100%;
  height: 80px;
  margin-bottom: 6px;
}
.black_bg {
  position: absolute;
  top: 0;
  left: 0;
  background: #960b23;
  width: 100%;
  height: 80px;
}
.black_bg1 {
  position: absolute;
  top: 0;
  left: 0;
  background: #4f0b96;
  width: 100%;
  height: 80px;
}
.black_bg2 {
  position: absolute;
  top: 0;
  left: 0;
  background: #0b5696;
  width: 100%;
  height: 80px;
}
.black_bg3 {
  position: absolute;
  top: 0;
  left: 0;
  background: #452b68;
  width: 100%;
  height: 80px;
}
.label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.label1 {
  background: rgba(14, 21, 48, 0.2);
  width: 1644px;
  height: 80px;
  display: flex;
  flex-direction: row;
  margin-left: 46px;
}
.image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.mrl13 {
  margin-left: 13px;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.end_l {
  width: 146px;
  margin-right: 30px;
  height: 100%;
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.9;
}
.flex {
  flex: 1;
}
.team_text {
  font-size: 40px;
  font-family: FZZDHJW;
  font-weight: normal;
  color: #e9e9e9;
  margin-left: 15px;
}
.score1 {
  font-size: 40px;
  font-family: FZPSCH;
  font-weight: normal;
  color: #03ffff;
}
.score2 {
  font-size: 40px;
  font-family: FZPSCH;
  font-weight: normal;
  color: #e0e0e0;
}
.score3 {
  font-size: 40px;
  font-family: FZPSCH;
  font-weight: normal;
  color: #929292;
}
.total_score1 {
  font-size: 40px;
  font-family: FZPSCH;
  font-weight: normal;
  color: #03ffff;
  margin-left: 109px;
  width: 80px;
}
.total_score {
  font-size: 28px;
  font-family: FZZDHJW;
  font-weight: normal;
  color: #03ffff;
  margin-left: 109px;
  width: 80px;
}
.w152 {
  width: 18px;
}
.w765 {
  width: 187px;
}
.rank_num {
  font-size: 44px;
  font-family: Bungee;
  font-weight: 400;
  color: #ffffff;
  margin-left: 50px;
  width: 50px;
}
.color1 {
  width: 6px;
  height: 60px;
  background: #ed6d34;
}
.color2 {
  width: 6px;
  height: 60px;
  background: #6f9ca7;
}
.color3 {
  width: 6px;
  height: 60px;
  background: #f1c352;
}
.color4 {
  width: 6px;
  height: 60px;
  background: #95be71;
}
.color5 {
  width: 6px;
  height: 60px;
  background: #59b8cc;
}
.color6 {
  width: 6px;
  height: 60px;
  background: #144e89;
}
.color7 {
  width: 6px;
  height: 60px;
  background: #2185b7;
}
.color8 {
  width: 6px;
  height: 60px;
  background: #a7bfa8;
}
.w98 {
  width: 46px;
}
.w107 {
  width: 107px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.w158 {
  width: 136px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.w165 {
  width: 165px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
